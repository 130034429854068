import React from 'react';

import s from './DeliveryInfo.module.scss';
import { FormGroup } from '../../../../../FormElements/FormGroup';
import { useT } from '@transifex/react';
import { budgetFormatter, numberToCurrency } from '../../../../../../utils/budget';

const DeliveryInfo = ({ guarantiedBudget, pricePerMessage }) => {
  const t = useT();
  return (
    <div className={s['delivery']}>
      <FormGroup title={t('Delivery')}>
        <div className={s['delivery__content']}>
          <span className={s['highlighted']}>{budgetFormatter(guarantiedBudget || 0)}</span>{' '}
          {t(
            'target consumers will opt-in to watch your full video ad, and engage by answering your questions.'
          )}
        </div>
        <div className={s['delivery__accented']}>
          {`${t('Cost per consumer relationship and session is')} ${numberToCurrency(
            pricePerMessage,
            false,
            false,
            4
          )}. ${t(
            'This includes fully completed video views, engagement, responses to all questions, and call to action images encouraging click-through to drive conversions, installs, social following, or visits to your website.'
          )}`}
        </div>
      </FormGroup>
    </div>
  );
};

export default DeliveryInfo;
