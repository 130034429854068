import { isNaN } from 'lodash';
import numeral from 'numeral';
import { CARBON_OFFSET_IMPACT_PERCENTAGE } from '../../constants/values';
import { getPricePerCarbonTonne } from '../reporting';
import currency from 'currency.js';
import {
  bcp47TagState,
  countryCodeState,
  currenciesSymbol,
  currencyCodeState,
  defaultBcp47Tag,
  defaultCountryCode,
  defaultCurrencyCode,
} from './constant';

export const setLabelPricePerMessage = (checkbox, tieredPricing) => {
  const bcp47Tag = bcp47TagState() || defaultBcp47Tag;
  const currencyCode = currencyCodeState() || defaultCurrencyCode;

  const videoAdPrice = tieredPricing.find(
    (el) => el.videoMaxTimeInSeconds === checkbox.videoMaxTimeInSeconds
  );

  if (videoAdPrice) {
    return new Intl.NumberFormat(bcp47Tag, {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: 4,
      maximumSignificantDigits: 4,
      roundingMode: 'floor',
    }).format(videoAdPrice.pricePerMessage);
  }

  return '-';
};

export const numberToThousandCurrency = (amount) => {
  const currencyCode = currencyCodeState() || defaultCurrencyCode;
  const countryCode = countryCodeState() || defaultCountryCode;
  const bcp47Tag = bcp47TagState() || defaultBcp47Tag;

  // The decimal digit, for the shorten amount || ex. 10,500 => 5
  let decimal = '';
  // Amount that will be deducted from the initial amount, for rounding purposes only
  let deduction = 0;

  if (amount > 1e6) {
    decimal = amount.toString()[amount.toString().length - 6];
    deduction = decimal * 1e5;
  } else if (amount > 1e4) {
    decimal = amount.toString()[amount.toString().length - 3];
    deduction = decimal * 1e2;
  }

  amount = amount - deduction;
  let thirdPart;
  if (!Number.isInteger(amount / 1000000)) {
    thirdPart = amount.toString()[2];
  }

  if (amount && currencyCode && countryCode) {
    // The exported amount from the function below, is without a decimal digit || ex. 10500 = 10K
    let shortenCurrencyString = new Intl.NumberFormat(bcp47Tag, {
      notation: 'compact',
      compactDisplay: 'short',
      style: 'currency',
      currency: currencyCode,
    }).format(amount);

    // Split the shorten amount into three parts || ex. 10.5K = ['10', '.5' , 'K']
    let firstPart, fourthPart;
    if (amount >= 1e6) {
      [firstPart, fourthPart] = shortenCurrencyString.split(/(?=M)/g);
      if (firstPart.includes('.')) firstPart = firstPart.slice(0, 2);
    } else if (amount >= 1e3) {
      [firstPart, fourthPart] = shortenCurrencyString.split(/(?=K)/g);
    }

    if (Number(decimal)) {
      const secondPart = '.' + decimal;
      shortenCurrencyString = thirdPart
        ? firstPart.concat(secondPart, thirdPart, fourthPart)
        : firstPart.concat(secondPart, fourthPart);
    }

    return shortenCurrencyString;
  } else return 0;
};

export const numberToCurrency = (amount, noDecimals, shortenThousands, decimalDigits = 2) => {
  const currencyCode = currencyCodeState() || defaultCurrencyCode;
  const countryCode = countryCodeState() || defaultCountryCode;
  const bcp47Tag = bcp47TagState() || defaultBcp47Tag;

  let options = {};

  options = (amount % 1 === 0 || noDecimals) && {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  if (currencyCode && countryCode) {
    if (shortenThousands && amount >= 10000) return numberToThousandCurrency(amount);

    return new Intl.NumberFormat(bcp47Tag, {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: decimalDigits,
      maximumSignificantDigits: decimalDigits + 1,
      roundingMode: 'floor',
      ...options,
    }).format(amount);
  } else return 0;
};

export const amountToCurrency = (amount, decimals = 2) => {
  return currency(amount, {
    symbol: currenciesSymbol[currencyCodeState()] || defaultCurrencyCode,
    precision: decimals,
    increment: 0,
  }).format();
};

export const getCurrencySymbol = () => {
  const currencyCode = currencyCodeState() || defaultCurrencyCode;
  const bcp47Tag = bcp47TagState() || defaultBcp47Tag;

  return (0)
    .toLocaleString(bcp47Tag, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
};

export const toFixedWithoutRounding = (amount, digits) =>
  Math.trunc(amount * Math.pow(10, digits)) / Math.pow(10, digits);

export const budgetFormatter = (value) =>
  value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.(\d{2}).*/, '.$1') : '';

export const creditCardFormatter = (value) => {
  const clearedValue = `${value}`.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = clearedValue.match(/\d{4,16}/g);
  const match = matches && (matches[0] || '');
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};

export const cvcCardFormatter = (value) => {
  const clearedValue = `${value}`.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = clearedValue.match(/\d{0,3}/g);

  return matches && (matches[0] || '');
};

export const checkCardType = (value) => {
  const modifiedValue = value.split(' ').join('');

  const amex = new RegExp('^3[47][0-9]{13}$');
  const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  const cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  const cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

  const mastercard = new RegExp('^5[1-5][0-9]{14}$');
  const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  const jcb = new RegExp('^35[0-9]{14}[0-9]*$');

  if (visa.test(modifiedValue)) {
    return 'visa';
  }
  if (amex.test(modifiedValue)) {
    return 'amex';
  }
  if (mastercard.test(modifiedValue) || mastercard2.test(modifiedValue)) {
    return 'mastercard';
  }
  if (disco1.test(modifiedValue) || disco2.test(modifiedValue) || disco3.test(modifiedValue)) {
    return 'discover';
  }
  if (diners.test(modifiedValue)) {
    return 'diners';
  }
  if (jcb.test(modifiedValue)) {
    return 'jcb';
  }
  if (cup1.test(modifiedValue) || cup2.test(modifiedValue)) {
    return 'china_union_pay';
  }
  return undefined;
};

export const stripeExpiryDateFormatter = (month, year) => {
  let formattedMonth;
  const formattedYear = `${year}`.substring(`${year}`.length - 2);

  if (month < 10) {
    formattedMonth = `${0}${month}`;
  } else {
    formattedMonth = `${month}`;
  }

  return `${formattedMonth}/${formattedYear}`;
};

export const expiryDateFormatter = (value) =>
  `${value}`
    .replace(/[^0-9]/g, '')
    .replace(/^([2-9])$/g, '0$1')
    .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
    .replace(/^0{1,}/g, '0')
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2');

export const getCardsLastFourDigits = (value) => `${value}`.substring(`${value}`.length - 5);

export const budgetParser = (value) =>
  typeof value === 'string' ? value.replace(/[^0-9.]+/g, '').replace(/\$\s?|(,*)/g, '') : value;

export const minimumCampaignDuration = (budget, dailyBudget) =>
  dailyBudget ? Math.floor(budget / dailyBudget) : '-';

export const calculateDailyBudget = (campaignDuration, budget) =>
  campaignDuration ? (budget / campaignDuration).toFixed(2).toString().replace('.00', '') : 0;

export const isEntryNaN = (entry) => !isNaN(entry) && typeof +entry === 'number';

export const percentageOfBudget = (percentage, budget) =>
  numberToFixedDecimals(percentage * budget, 2);

export const carbonOffsetInTones = (budget, countryCode) => {
  const result =
    percentageOfBudget(CARBON_OFFSET_IMPACT_PERCENTAGE, budget) /
    getPricePerCarbonTonne(countryCode);
  return Number(result.toFixed(1)) === 0.0 ? Math.trunc(result) : result.toFixed(1);
};

export const numberToSubCurrency = (value) => {
  const countryCode = countryCodeState() || defaultCountryCode;

  if (countryCode === 'US' || countryCode === 'AU' || countryCode === 'NZ')
    return `${Math.round(value * 100)}c`;
  else if (countryCode === 'GB') return `${Math.round(value * 100)}p`;
  else return `${numeral(value).format('0.00')}`;
};

export const selectCurrencyUnit = (amount, currencySymbol) => {
  if (amount < 1) {
    return amount * 100 + 'p';
  } else return `${amount}${currencySymbol}`;
};

export const stringToNumber = (value) => {
  if (typeof value === 'string') {
    if (value.includes(',')) return Number(value.replaceAll(',', ''));
    return Number(value);
  }
  return value;
};

// export const treesToBePlanted = (budget) =>
//   Math.floor(percentageOfBudget(CARBON_OFFSET_IMPACT_PERCENTAGE, budget) / 2 / PRICE_PER_TREE);

export const numberToFixedDecimals = (amount, decimals) => {
  const divisor = Math.pow(10, decimals);
  return Math.floor(amount * divisor) / divisor;
};
