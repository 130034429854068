import { T, useT } from '@transifex/react';
import React, { useCallback, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { constructTestId } from '../../../../../utils/test-ids';
import { FileSpecifications } from '../../../../common/FileSpecifications';
import { UploadZone } from '../../../../common/UploadZone';
import { FormGroup } from '../../../../FormElements/FormGroup';
import style from './ImageMedia.module.scss';
import { ModalCrop } from '../../../../ModalCrop';
import { base64StringToFile, extractImageFileExtension } from '../../../../../utils/media';
import s from '../AdNameAndCreative.module.scss';
import sponsorshipConstants from '../../../../../constants/basicAd/sponsorship-data';

const SECTION = CREATE_CAMPAIGN_PAGE.AD_NAME_AND_CREATIVE;

const ImageMedia = ({
  sponsorshipCreate,
  control,
  errors,
  creativeRef,
  setValue,
  handleChangeImageAd,
}) => {
  const t = useT();
  const [processingImageCover, setProcessingImageCover] = useState(undefined);

  const [cropModalIsOpen, setCropModalIsOpen] = useState(false);
  const cropperRef = useRef(null);
  const watchAdImageFile = useWatch({ control, name: 'adImageFile' });

  const handleCropImage = useCallback(() => {
    const cropper = cropperRef?.current.cropper;

    const cropperData = cropper
      .getCroppedCanvas({
        maxWidth: 2048,
        maxHeight: 2048,
        imageSmoothingQuality: 'high',
      })
      .toDataURL();

    const fileExtension = extractImageFileExtension(cropperData);
    const fileName = `ctaCover${sponsorshipCreate.name}-cropped.${fileExtension}`;
    const croppedFile = base64StringToFile(cropperData, fileName);
    const croppedUrl = URL.createObjectURL(croppedFile);

    // Create an Image object to get the dimensions
    const image = new Image();
    image.onload = () => {
      const fileWidth = image.naturalWidth;
      const fileHeight = image.naturalHeight;

      // Set the dimensions and other values
      setValue('adVideo', croppedUrl);
      setValue('adVideoFile', croppedFile);
      setValue('imageDimensions', { height: fileHeight, width: fileWidth });
      setProcessingImageCover(croppedUrl);
      setCropModalIsOpen(false);
    };
    image.src = cropperData;
  }, [setValue, sponsorshipCreate.name]);

  const handleCloseCropModal = useCallback(() => {
    setCropModalIsOpen(false);
  }, []);

  const handleChangeImageCover = useCallback(
    (acceptedFiles) => {
      const imageCover = URL.createObjectURL(acceptedFiles[0]);
      setProcessingImageCover(imageCover);
      setValue('adImageFile', acceptedFiles[0].name);
      setValue('adVideoFile', acceptedFiles[0].name);
      setValue('contentType', acceptedFiles[0].type);

      const blob = acceptedFiles[0].slice(0, acceptedFiles[0].size, acceptedFiles[0].type);
      const correctFileName = acceptedFiles[0].name
        .replace(/[&\\/\\#,+()$~%'":*?<>{}]/g, '')
        .toLowerCase();
      const newFile = new File([blob], correctFileName, { type: acceptedFiles[0].type });

      const adVideo = URL.createObjectURL(newFile);
      const adImageFile = newFile;

      setValue('adVideo', adVideo);
      setValue('adVideoFile', adImageFile);

      setCropModalIsOpen(true);
    },
    [setValue]
  );

  return (
    <>
      <div ref={creativeRef} className="basic-ad-media">
        <FormGroup
          title={t(`Upload Image Ad`)}
          subtitle={t('This is the brand video that will communicate directly with real people…')}
          required
        >
          <div className={s['sponsorship__inner-video__subtitle']}>
            <span>
              <T
                _str="If you need to create a video file first, please use {link} link here."
                link={
                  <a href={sponsorshipConstants.canvaUrl} target="_blank" rel="noopener noreferrer">
                    canva
                  </a>
                }
              />
            </span>
          </div>
          <div className={style['image-media__content']}>
            <Controller
              control={control}
              name="adVideo"
              render={({ field: { value, ref } }) => {
                if (!value) setProcessingImageCover(undefined);
                return (
                  <UploadZone
                    inputRef={ref}
                    data-testid={constructTestId(SECTION, 'img-upload')}
                    styleType="portrait"
                    name="adVideo"
                    type="image"
                    control={control}
                    error={errors.adVideo}
                    handleChange={handleChangeImageCover}
                    contentType="image/jpeg, image/png"
                    fileUrl={processingImageCover || value}
                    hasFile={watchAdImageFile?.name}
                  />
                );
              }}
            />
            <FileSpecifications fileSpecs={sponsorshipConstants.ctaImageFileSpecs} />
          </div>
        </FormGroup>
      </div>
      {cropModalIsOpen && (
        <ModalCrop
          aspectRatio={9 / 16}
          imageUri={processingImageCover}
          cropperRef={cropperRef}
          actionCallback={handleCropImage}
          closeCallback={handleCloseCropModal}
        />
      )}
    </>
  );
};

export default ImageMedia;
